import React from 'react'

import Title from '@components/title'
import InputText from '@components/input-text'

const FilterPrice = ({ onChangeMin, onChangeMax }) => {
  return (
    <div className="mb5">
      <Title
        heading="h6"
        noUnderline
        className="mb3-sm mb4"
        fontWeight="normal"
      >
        Hinta
      </Title>

      <div className="flex items-center">
        <InputText
          placeholder="Min €"
          type="number"
          min={0}
          onChange={onChangeMin}
          filterStyle
        />
        <span className="mh3-sm mh4">-</span>
        <InputText
          placeholder="Max €"
          type="number"
          min={0}
          onChange={onChangeMax}
          filterStyle
        />
      </div>
    </div>
  )
}

export default FilterPrice
