import React from 'react'

import Title from '@components/title'
import Checkbox from '@components/checkbox'

const FilterRooms = ({ onChange }) => {
  return (
    <div>
      <Title
        heading="h6"
        noUnderline
        className="mb3-sm mb4"
        fontWeight="normal"
      >
        Huoneita
      </Title>

      <ul className="list">
        <Item label="1h+k" name="yksi_huone" onChange={onChange} />
        <Item label="2h+k" name="kaksi_huonetta" onChange={onChange} />
        <Item label="3h+k" name="kolme_huonetta" onChange={onChange} />
        <Item label="4h+k" name="nelja_huonetta" onChange={onChange} />
      </ul>
    </div>
  )
}

export default FilterRooms

const Item = ({ label, name, onChange }) => (
  <li>
    <Checkbox label={label} name={name} onChange={onChange} />
  </li>
)
