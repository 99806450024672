import React from 'react'

import './styles.css'

const Loader = () => (
  <div className="loader">
    <div className="loader__dot" />
    <div className="loader__dot" />
    <div className="loader__dot" />
    <div className="loader__dot" />
    <div className="loader__dot" />
  </div>
)

export default Loader
