import React from 'react'

import Select from 'react-select'

import './styles.css'

const selectStyles = {
  container: styles => {
    return {
      ...styles,
      width: '100%'
    }
  },
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 16,
      paddingLeft: 12,
      paddingTop: 6,
      paddingBottom: 6,
      borderColor: isFocused ? '#9aca3c' : '#ccc',
      boxShadow: isFocused && '0 0 0 1px #9aca3c',
      ':hover': {
        borderColor: isFocused ? '#9aca3c' : '#ccc'
      }
    }
  },
  menu: styles => {
    return {
      ...styles,
      zIndex: 999
    }
  }
}

const InputSelect = ({ options, name, isMulti, ...rest }) => {
  return (
    <Select
      isMulti={isMulti}
      name={name}
      options={options}
      styles={selectStyles}
      classNamePrefix="custom-select"
      {...rest}
    />
  )
}

export default InputSelect
