import React from 'react'

import InputSelect from '@components/input-select'
import RoundedButton from '@components/rounded-button'

import Grid from './icons/grid.inline.svg'
import List from './icons/list.inline.svg'

const HousesListHeader = ({ listView, onViewChange, onOrderChange }) => {
  const adsOptions = [
    { value: 'createdAt_DESC', label: 'Viimeksi lisätyt' },
    { value: 'vuokra_ASC', label: 'Alin hinta ensin' },
    { value: 'vuokra_DESC', label: 'Ylin hinta ensin' }
  ]

  return (
    <div className="flex items-center mb5-sm mb6">
      <div className="mw340 w-100">
        <InputSelect
          name="Ads"
          defaultValue={adsOptions[0]}
          options={adsOptions}
          onChange={onOrderChange}
        />
      </div>
      <div className="flex ml-auto dn-sm">
        <div className="ml4">
          <RoundedButton
            onClick={() => onViewChange('list')}
            size={58}
            isActive={listView === 'list'}
          >
            <List />
          </RoundedButton>
        </div>
        <div className="ml4">
          <RoundedButton
            onClick={() => onViewChange('grid')}
            size={58}
            isActive={listView === 'grid'}
          >
            <Grid />
          </RoundedButton>
        </div>
      </div>
    </div>
  )
}

export default HousesListHeader
