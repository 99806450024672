import React, { Component } from 'react'
import { request } from 'graphql-request'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'
import Title from '@components/title'
import Loader from '@components/loader'
import TopBanner from '@components/top-banner'
import { API_URL } from '@constants/common'

import Asunto from './asunto'

import HousesListHeader from '@page/asunnot/houses-list-header'
import HousesList from '@page/asunnot/houses-list'
import FilterForm from '@page/asunnot/filter-form'
import '@page/asunnot/styles.css'

const Asunnot = () => {
  return (
    <Router>
      <AllApartments path="/asunnot" />
      <Asunto path="/asunnot/:id" />
    </Router>
  )
}

export default Asunnot

class AllApartments extends Component {
  state = {
    asunnot: [],
    loading: true,
    listView: 'grid',
    allFilters: '',
    minPrice: '',
    maxPrice: '',
    minArea: '',
    maxArea: '',
    houseTypes: [],
    rooms: [],
    cities: [],
    orderBy: 'createdAt_DESC'
  }

  componentDidMount() {
    this.fetchApartments()
  }

  componentDidUpdate(prevProp, prevState) {
    const { allFilters, orderBy } = this.state

    if (prevState.allFilters !== allFilters || prevState.orderBy !== orderBy) {
      this.fetchApartments()
    }
  }

  fetchApartments() {
    const { allFilters, orderBy } = this.state
    const fetchAsunnot = async () => {
      const { asunnot } = await request(
        API_URL,
        `
          {
            asunnot(${allFilters}, orderBy: ${orderBy}) {
              id,
              nimi,
              talonTyyppi
              asunnonTyyppi
              vuokra
              pintaAla
              alue {
                nimi
              }
              katuosoite
              postinumero
              rakennusvuosi
              hissi
              parveke
              laajakaista
              pyoravarasto
              muuttovalmis
              asunnonKuvat {
                handle
                height
                width
              }
              pohjapiirros {
                handle
                height
                width
              }
            }
          }
        `
      )
      this.setState({ asunnot, loading: false })
    }
    fetchAsunnot()
  }

  onInputNumberChange = (e, stateName) => {
    const value = +e.target.value
    this.setState({ [`${stateName}`]: value })
  }

  onTypeCheck = e => {
    const value = e.target.name
    const checked = e.target.checked
    const { houseTypes } = this.state

    if (checked) {
      this.setState({ houseTypes: [...houseTypes, value] })
    } else {
      const index = houseTypes.findIndex(t => t === value)
      houseTypes.splice(index, 1)
      this.setState({ houseTypes })
    }
  }

  onRoomCheck = e => {
    const value = e.target.name
    const checked = e.target.checked
    const { rooms } = this.state

    if (checked) {
      this.setState({ rooms: [...rooms, value] })
    } else {
      const index = rooms.findIndex(t => t === value)
      rooms.splice(index, 1)
      this.setState({ rooms })
    }
  }

  onCitySelect = values => {
    const mappedValues = values ? values.map(v => `"${v.value}"`) : []
    this.setState({ cities: mappedValues })
  }

  onOrderChange = order => {
    this.setState({ orderBy: order.value })
  }

  onSearchClick = () => {
    const {
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      houseTypes,
      rooms,
      cities
    } = this.state

    const queries = `
      ${minPrice ? `{ vuokra_gte: ${minPrice} },` : ''}
      ${maxPrice ? `{ vuokra_lte: ${maxPrice} },` : ''}
      ${minArea ? `{ pintaAla_gte: ${minArea} },` : ''}
      ${maxArea ? `{ pintaAla_lte: ${maxArea} },` : ''}
      ${houseTypes.length ? `{ talonTyyppi_in: [${houseTypes}] },` : ''}
      ${rooms.length ? `{ asunnonTyyppi_in: [${rooms}] },` : ''}
      ${cities.length ? `{ alue_every: { nimi_in: [${cities}] } },` : ''}
    `

    this.setState({ allFilters: `where: { AND: [${queries}] }` })
  }

  onFiltersClear = () => {
    this.setState({
      allFilters: '',
      minPrice: '',
      maxPrice: '',
      minArea: '',
      maxArea: '',
      houseTypes: [],
      rooms: [],
      cities: []
    })
  }

  onListViewChange = listView => {
    this.setState({ listView })
  }

  render() {
    const { uri } = this.props
    const { asunnot, loading, listView } = this.state

    return (
      <Layout currentLocation={uri}>
        <Helmet>
          <title>Asunnot | Tuulia Kodit</title>
        </Helmet>
        <TopBanner title="Löydä" subtitle="oma Tuulia Kotisi" />

        <FilterForm
          resultLength={asunnot.length}
          onCitySelect={this.onCitySelect}
          onTypeCheck={this.onTypeCheck}
          onRoomCheck={this.onRoomCheck}
          onInputNumberChange={this.onInputNumberChange}
          onSearchClick={this.onSearchClick}
          onFiltersClear={this.onFiltersClear}
        />

        <section className="section section--gray section--bg">
          <div className="container">
            <Title
              color="dark-gray"
              heading="h5"
              noUnderline
              className="mb4-sm mb6"
              fontWeight="normal"
            >
              Löydettiin <span className="navy fw6">{asunnot.length} </span>
              asuntoa, jotka vastasivat hakuehtoja
            </Title>

            <HousesListHeader
              onOrderChange={this.onOrderChange}
              onViewChange={this.onListViewChange}
              listView={listView}
            />

            {loading ? (
              <Loader />
            ) : (
              <HousesList asunnot={asunnot} listView={listView} />
            )}
          </div>
        </section>
      </Layout>
    )
  }
}
