import React from 'react'

import Button from '@components/button'

const FilterFooter = ({ onSearch, onClear, resultLength }) => {
  return (
    <div className="flex flex-wrap items-center buttons-group">
      {/* <span className="josefin f6">
        Search Conditions Include{' '}
        <span className="navy fw6">{resultLength}</span>
      </span> */}
      <Button className="ml-auto" onClick={onSearch}>
        Etsi
      </Button>

      <Button color="white" className="ml-auto" onClick={onClear} type="reset">
        Tyhjennä
      </Button>
    </div>
  )
}

export default FilterFooter
