import React, { useEffect, useState } from 'react'
import { request } from 'graphql-request'

import Title from '@components/title'
import InputSelect from '@components/input-select'
import { API_URL } from '@constants/common'

const FilterCity = ({ onChange }) => {
  const [alueet, setAlueet] = useState([])
  useEffect(() => {
    const fetchAlueet = async () => {
      const { alueet } = await request(API_URL, `{ alueet { nimi } }`)
      setAlueet(alueet)
    }
    fetchAlueet()
  }, [])

  return (
    <div>
      <Title
        heading="h6"
        noUnderline
        className="mb3-sm mb4"
        fontWeight="normal"
      >
        Alue
      </Title>

      <InputSelect
        isMulti
        name="Alue"
        options={alueet.map(alue => {
          return { value: alue.nimi, label: alue.nimi }
        })}
        onChange={onChange}
      />
    </div>
  )
}

export default FilterCity
