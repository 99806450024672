import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { currencyFormatter, areaFormatter, roomFormatter } from '@utils'
import Image from '@components/image'
import Title from '@components/title'

import { dateFormatter } from '@utils'


import Location from './icons/location.inline.svg'

const HousesList = ({ asunnot = [], listView }) => {
  return (
    <div className="flex flex-wrap nh3">
      {asunnot.map(asunto => {
        const {
          id,
          nimi,
          talonTyyppi,
          asunnonTyyppi,
          vuokra,
          pintaAla,
          rakennusvuosi,
          alue = {},
          katuosoite,
          postinumero,
          kuvat,
          asunnonKuvat,
          hissi,
          parveke,
          laajakaista,
          pyoravarasto,
          pohjapiirros,
          muuttovalmis
        } = asunto

        // Convert muuttovalmis to a Date object and compare with the current date
        const muuttovalmisDate = new Date(muuttovalmis);
        const currentDate = new Date();
        const isMuuttovalmisFuture = muuttovalmisDate > currentDate;

        return (
          <div
            className={classNames({
              ph3: true,
              'w-100-xs w-50-sm w-50-m w-third-l ph3 mb4': listView === 'grid',
              'w-100 mb3': listView === 'list'
            })}
            key={id}
          >
            <Link
              to={`/asunnot/${id}`}
              className={classNames({
                'db bg-white br4 overflow-hidden card-shadow ba b--light-gray': true,
                'josefin no-underline h-100 flex': true,
                'flex-column': listView === 'grid'
              })}
            >
              <div
                className={classNames({
                  'w256 flex-shrink-0': listView === 'list'
                })}
              >
                <div
                  className={classNames({
                    'aspect-ratio ': true,
                    'aspect-ratio--6x4': listView === 'grid',
                    'aspect-ratio--1x1': listView === 'list'
                  })}
                >
                  {asunnonKuvat.length > 0 ? (
                    <Image image={asunnonKuvat[0]} isAbsolute />
                  ) : (
                    <Image image={pohjapiirros} isAbsolute />
                  )}
                </div>
              </div>

              <div
                className={classNames({
                  'pa4 flex-grow-1 flex': true,
                  'flex-column': listView === 'grid'
                })}
              >
                <div
                  className={classNames({
                    'flex-grow-1': true,
                    'br b--light-gray': listView === 'list'
                  })}
                >
                  <div className="mb3 mb4-l">
                    <Title
                      heading="h6"
                      className="mb2"
                      color="dark-gray"
                      noUnderline
                    >
                      {nimi}
                    </Title>
                    <p className="mv0 gray-blue truncate f9-sm f8-m f7-l">
                      {roomFormatter(asunnonTyyppi)}
                      {/* , {hissi && 'Hissi, '}
                      {parveke && 'Parveke, '}
                      {laajakaista && 'Laajakaista, '}
                      {pyoravarasto && 'Pyoravarasto, '} */}
                    </p>
                  </div>

                  <div
                    className={classNames({
                      'mb4 mb5-l': listView === 'grid'
                    })}
                  >
                    <div className="flex">
                      <Location className="flex-shrink-0" />
                      <div className="ml3">
                        <span className="db mb1 lh-title">
                          {katuosoite}, {postinumero}, {alue[0].nimi}
                        </span>
                        <span className="silver db mb3">
                          {talonTyyppi}, {rakennusvuosi}
                        </span>
                      </div>
                    </div>
                    <span className="db green f9 f8-l underline">
                    {isMuuttovalmisFuture ? `Muuttovalmis ${dateFormatter(muuttovalmis)}` : 'Heti vapaa'}
                    </span>
                  </div>
                </div>

                <div
                  className={classNames({
                    'flex flex-wrap': true,
                    'items-center mt-auto': listView === 'grid',
                    'flex-column justify-between pl5': listView === 'list'
                  })}
                >
                  <div className="flex items-center nowrap f9 f8-l fw6">
                    <span className="navy">{currencyFormatter(vuokra)}</span>
                    <span className="green ml2">
                      | {areaFormatter(pintaAla)}
                    </span>
                  </div>

                  <button
                    className={classNames({
                      'dib pv1-sm pv2 ph2-sm ph3 br5 b--solid bw1 ttu nowrap josefin': true,
                      'lh-text bg-white b--light-gray gray f13-sm f12 fw5 ml-auto': true
                    })}
                    type="button"
                  >
                    <span className="ph2">Katso tiedot</span>
                  </button>
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default HousesList
