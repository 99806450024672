import React from 'react'
import classNames from 'classnames'

import './styles.css'

const Checkbox = ({ label, onChange, name, isRadio, value }) => {
  return (
    <label className="checkbox-container mb4 relative flex items-center pointer">
      <input
        type={isRadio ? 'radio' : 'checkbox'}
        name={name || label}
        value={value}
        onChange={onChange}
        className="o-0 absolute top-0 left-0"
      />
      <span
        className={classNames({
          'checkbox-mark ba b--moon-gray': true,
          'mr3-sm mr4 relative flex-shrink-0': true,
          'checkbox-mark--radio': isRadio
        })}
      />
      <span className="lh-title">{label}</span>
    </label>
  )
}

export default Checkbox
