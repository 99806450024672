import React from 'react'

import FilterHouseType from './filter-house-type'
import FilterCity from './filter-city'
import FilterRooms from './filter-rooms'
import FilterPrice from './filter-price'
import FilterArea from './filter-area'
import FilterFooter from './filter-footer'

const FilterForm = ({
  resultLength,
  onCitySelect,
  onTypeCheck,
  onRoomCheck,
  onInputNumberChange,
  onSearchClick,
  onFiltersClear
}) => {
  return (
    <div className="container pv6">
      <form>
        <div className="flex flex-wrap mb5 nh3">
          <div className="w-100 w-50-m w-30-l ph3 order-3 order-0-l">
            <FilterCity onChange={onCitySelect} />
          </div>

          <div className="w-50 w-25-m w-20-l ph3 mb4 mb0-l flex flex-column items-center-l order-0 order-1-l">
            <FilterHouseType onChange={e => onTypeCheck(e)} />
          </div>

          <div className="w-50 w-25-m w-20-l ph3 mb4 mb0-l flex flex-column items-center-l order-1 order-2-l">
            <FilterRooms onChange={e => onRoomCheck(e)} />
          </div>

          <div className="w-100 w-50-m w-30-l mb5-sm mb4-m ph3 order-2 order-3-l">
            <FilterPrice
              onChangeMin={e => onInputNumberChange(e, 'minPrice')}
              onChangeMax={e => onInputNumberChange(e, 'maxPrice')}
            />

            <FilterArea
              onChangeMin={e => onInputNumberChange(e, 'minArea')}
              onChangeMax={e => onInputNumberChange(e, 'maxArea')}
            />
          </div>
        </div>

        <FilterFooter
          resultLength={resultLength}
          onSearch={onSearchClick}
          onClear={onFiltersClear}
        />
      </form>
    </div>
  )
}

export default FilterForm
