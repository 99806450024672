import React from 'react'
import classNames from 'classnames'

const RoundedButton = ({ onClick, isActive, areaLabel, children, size }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames({
      'bw0 pa1 br-100': true,
      'bg-white green': !isActive,
      'bg-green white': isActive
    })}
    area-label={areaLabel}
    style={{ width: size, height: size }}
  >
    <div className="h-100 flex items-center justify-center">{children}</div>
  </button>
)

export default RoundedButton
